<template>
    <div id="app">
        <div v-if="!errorModal">
            <Header
                :title="configurator.title"
                :subtitle="configurator.subtitle"
                :logo="config.logo"
                :logo-link="config.logoLink"
                :logo-alt="config.logoAlt"
            />
            <b-container
                v-if="configurator.heroImage"
                class="hero-image-container"
                :style="{'background-image': 'url(' +configurator.heroImage+ ')'}">
            </b-container>
            <MainNavigation :items="navigationSteps"
                            :available-until="stepsAvailableUntil"
                            :step-status="stepStatus"
                            :current-index="currentIndex"
                            @goto="currentIndex = $event"
            />
            <component
                v-if="currentStep && currentStep.type !== 'summary'"
                :is="getComponent(currentStep.type)"
                :config="currentStep"
                v-model="currentStepData"
                :total-price="totalPrice"
            />
            <Summary :data="data"
                     :steps="steps"
                     :total-price="totalPrice"
                     :configurator="configurator"
                     v-else-if="currentStep && currentStep.type === 'summary'"/>
            <TermCondition v-if="currentIndex === steps.length - 1 && config.termsConditions"
                           :config="config.termsConditions"
            />
            <Captcha v-if="captchaType == 'internal' && currentIndex === steps.length - 1"
                     :image="captchaImage"
                     v-model="captchaSolution"
                     :error="captchaError"
            />
            <HCaptcha :sitekey="hcaptchaSiteKey"
                      v-model="captchaSolution"
                      :error="captchaError"
                      v-if="captchaType === 'hcaptcha'"/>
            <StepNavigation
                :next="stepStatus[currentIndex] !== 'invalid'"
                :last="currentIndex === steps.length - 1"
                :prev="currentIndex !== 0"
                @next="nextStep"
                @prev="previousStep"
            />

            <MainNavigation :items="navigationSteps"
                            :available-until="stepsAvailableUntil"
                            :step-status="stepStatus"
                            :current-index="currentIndex"
                            @goto="currentIndex = $event"
            />
            <Footer/>
        </div>
        <b-modal size="xl" v-else
                 v-model="errorModal"
                 no-close-on-esc
                 no-close-on-backdrop
                 hide-header
                 hide-footer
        >
            <b-alert show v-if="errorMessage" variant="danger">{{ errorMessage }}</b-alert>
            <pre>{{ error }}</pre>
        </b-modal>
        <b-modal
                 v-model="submitModal"
                 :no-close-on-esc="submitBlock"
                 :no-close-on-backdrop="submitBlock"
                 ok-only
                 hide-header
                 :hide-footer="submitBlock"
        >
            <div class="text-center" v-if="submitBusy"><b-spinner/></div>
            <b-alert variant="success" v-if="submitSuccess" show>
                {{ $tc('submit.success') }}
            </b-alert>
            <b-alert variant="danger" v-if="submitError" show>
                {{submitError}}
            </b-alert>
        </b-modal>
        <ConsentModal/>
<!--        <pre>-->
<!--            {{JSON.stringify(stepStatus[currentIndex], null, 2)}}-->
<!--            {{JSON.stringify(dataFiltered, null, 2)}}-->
<!--        </pre>-->
    </div>
</template>

<script>
import Captcha from "./components/form/Captcha";
import Header from "./components/base/Header";
import Footer from "./components/base/Footer";
import MainNavigation from "./components/base/MainNavigation";
import Form from "./components/step/Form";
import Selection from "./components/step/Selection";
import Summary from "./components/step/Summary";
import validateSchema from './schema';
import StepNavigation from "./components/base/StepNavigation";
import TermCondition from "./components/base/TermCondition";
import ConsentModal from "./components/ConsentModal";
import HCaptcha from "./components/form/HCaptcha";
export default {
    name: 'App',
    components: {Captcha, ConsentModal, TermCondition, StepNavigation, Selection, Form, Summary, Footer, Header, MainNavigation, HCaptcha},
    created() {
        this.fetchConfig();
    },
    data() {
        return {
            captchaError: false,
            captchaImage: '',
            captchaToken: '',
            captchaSolution: '',
            captchaTimeout: null,
            captchaType: '',
            hcaptchaSiteKey: '',
            config: {
                logo: '',
                logoAlt: '',
                logoLink: '',
                configurators: [],
                steps: [],
                termsConditions: []
            },
            currentIndex: 0,
            errorModal: false,
            error: [],
            errorMessage: '',
            data: [],
            storeTimeout: -1,
            submitBlock: false,
            submitBusy: false,
            submitError: '',
            submitModal: false,
            submitSuccess: false
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                if (this.storeTimeout >= 0) {
                    return;
                }
                this.storeTimeout = setTimeout(() => {
                    this.saveState();
                }, 3000);
            }
        }
    },
    computed: {
        configurator() {
            if (this.config.configurators.length === 0) {
                return {
                    title: '',
                    subtitle: '',
                    logo: '',
                    logoAlt: '',
                    logoLink: '',
                    exclude: []
                };
            }
            const name = this.$store.getters.configuator;
            const configObject = this.config.configurators.find(c => c.name === name);
            if (!configObject) {
                return this.config.configurators[0];
            }
            return configObject;
        },
        navigationSteps() {
            return this.steps.map(s => s.navigationTitle);
        },
        steps() {
            return this.config.steps.filter(step => {
                if (step.type === 'summary') {
                    return true;
                }
                const stepData = this.dataFiltered.filter(s => !('productGroups' in s) || s.productGroups.length > 0 )
                                                  .find(sd => sd.id === step.id);
                return stepData !== undefined;
            });
        },
        currentStep() {
            return this.steps[this.currentIndex];
        },
        currentStepData() {
            return this.dataFiltered.filter(i => i.id === this.currentStep.id)[0];
        },
        /**
         * only contains data fields which are not excluded by the current configurator configuration
         */
        dataFiltered() {
            return this.data.map(step => {
                if ('productGroups' in step) {
                    return {
                        ...step,
                        productGroups: step.productGroups.filter(pg => !this.configurator.exclude.includes(pg.id))
                    };
                } else {
                    return step;
                }
            })
        },
        stepStatus() {
            let status = [];
            for (const step of this.steps) {
                if (step.type === 'summary') {
                    status.push('summary');
                } else if (step.type === 'selection') {
                    const stepConfig = this.dataFiltered.find(i => i.id === step.id);
                    if (!stepConfig) {
                        // step has no productGroups at all (e.g. all are excluded)
                        status.push('valid');
                    }
                    const invalidGroup = stepConfig.productGroups.find(pg => {
                        return (!pg.skipped && pg.products.filter(p => p.selected).length === 0);
                    });
                    if (invalidGroup) {
                        status.push('invalid');
                    } else {
                        status.push('valid');
                    }
                } else if (step.type === 'form') {
                    const stepConfig = this.dataFiltered.find(i => i.id === step.id);
                    const invalid = step.fieldsets.find(fieldset => {
                        const fieldsetConfig = stepConfig.fieldsets.find(fs => fs.id === fieldset.id);
                        const invalidElement = fieldset.fields.find(field => field.required &&
                                                            fieldsetConfig.fields.find(f => f.id === field.id && f.value.length === 0));
                        return invalidElement !== undefined;
                    });
                    if (invalid !== undefined) {
                        status.push('invalid');
                    } else {
                        status.push('valid');
                    }
                } else {
                    throw "unknown step type " + step.type;
                }
            }
            return status;
        },
        stepsAvailableUntil() {
            let availUntil = 0;
            for (const status of this.stepStatus) {
                if (status === 'invalid') {
                    return availUntil;
                }
                availUntil += 1;
            }
            return availUntil;
        },
        totalPrice() {
            let price = this.$getDinero(0);
            this.data.forEach(step => {
                if (!('productGroups' in step)) {
                    return; // type == form
                }
                step.productGroups.filter(pg => !this.configurator.exclude.includes(pg.id)).forEach(productGroupData => {
                    productGroupData.products.filter(p => p.selected).forEach(productData => {
                        const product = this.$store.getters.getProduct(productData.id);
                        if ('price' in product && product.price > 0) {
                            price = price.add(this.$getDinero(product.price));
                        }
                        productData.options.filter(o => o.value.length > 0).forEach(optionData => {
                            optionData.value.forEach(optionValueData => {
                                const optionValue = this.$store.getters.getProductOptionValue(productData.id, optionData.id, optionValueData);
                                if (optionValue && 'price' in optionValue && optionValue.price > 0) {
                                    price = price.add(this.$getDinero(optionValue.price));
                                }
                            });
                        });
                    })
                });
            });
            return this.$tc('global.priceSumPrefix') + this.$dineroFormat(price);
        }
    },
    methods: {
        getComponent(type) {
            switch (type) {
                case 'selection':
                    return 'Selection';
                case 'form':
                    return 'Form';
                case 'summary':
                    return 'Summary';
            }
            return '';
        },
        async fetchConfig() {
            let response = null;
            try {
                response = await this.$axios.get('./config');
            } catch (error) {
                let errorMsg = this.$tc('error.configNotFound');
                if (error.response && 'message' in error.response.data) {
                    errorMsg += ' ' + error.response.data.message;
                }
                this.showError(errorMsg);
                return;
            }
            if ('object' !== typeof response.data) {
                this.showError('', this.$tc('error.syntaxError'));
                return;
            }
            const config = response.data;
            const valid = validateSchema(config);
            if (!valid) {
                this.showError(validateSchema.errors, this.$tc('error.invalidSchema'))
                console.log(validateSchema.errors);
            } else {
                let configLocal = JSON.parse(JSON.stringify(config));

                this.captchaType = configLocal.captcha;
                if (this.captchaType === 'hcaptcha' && 'hcaptchaSiteKey' in configLocal) {
                    this.hcaptchaSiteKey = configLocal.hcaptchaSiteKey;
                }

                configLocal.steps = config.steps.filter(i => i.type === 'selection');
                configLocal.steps.push({
                    type:'summary',
                    title: this.$tc('navigation.summary'),
                    navigationTitle: this.$tc('navigation.summary')
                });
                configLocal.steps = configLocal.steps.concat(config.steps.filter(i => i.type === 'form'));

                try {
                    this.addUserFields(configLocal);
                    this.config = Object.assign({}, configLocal);
                } catch (e) {
                    this.showError(e, this.$tc('error.invalidSchema'))
                }
                try {
                    this.loadState();
                } catch (e) {
                    console.error('could not load configuration');
                    console.error(e);
                    // might happen if the structed of the config.json changed too much so an old saved state is
                    // not compatible. In this case we just drop the saved config.
                    // (will happen automatically as the config gets overwritten all 3 secs)
                }
                this.fetchCaptcha();
            }
        },
        createProducts() {
            this.config.products.forEach(product => {
                this.$store.commit('addProduct', product);
            });
        },
        addUserFields(config) {
            const data = [];
            config.steps.forEach((step) => {
                if (step.type === 'selection') {
                    const stepData = {
                        id: step.id,
                        productGroups: []
                    };
                    step.productGroups.forEach((productGroup) => {
                        this.$store.commit('addProductGroup', productGroup);
                        const productGroupData = {
                            id: productGroup.id,
                            products: [],
                            skipped: false
                        }
                        productGroup.products.forEach(product => {
                            this.$store.commit('addProduct', product);
                            const productData = {
                                id: product.id,
                                selected: false,
                                options: []
                            };
                            product.options.forEach(option => {
                                productData.options.push({
                                    id: option.id,
                                    value: []
                                });
                            });
                            productGroupData.products.push(productData);
                        });
                        stepData.productGroups.push(productGroupData);
                    });
                    data.push(stepData);
                } else if (step.type === 'form') {
                    const stepData = {
                        id: step.id,
                        fieldsets: []
                    };
                    step.fieldsets.forEach(fieldset => {
                        const fieldsetData = {
                            id: fieldset.id,
                            fields: []
                        };
                        fieldset.fields.forEach(field => {
                            fieldsetData.fields.push({
                                id: field.id,
                                value: []
                            });
                        });
                        stepData.fieldsets.push(fieldsetData);
                    });
                    data.push(stepData);
                }
            });
            this.data = data;
        },
        saveState() {
            if (localStorage.getItem('consent') !== 'true') {
                return;
            }
            this.storeTimeout = -1;
            const state = {
                configurator: this.$store.getters.configuator,
                subtitle: this.$store.getters.subtitle,
                data: this.data
            };
            localStorage.setItem('settings', JSON.stringify(state));
        },
        loadState() {
            const settings = JSON.parse(localStorage.getItem('settings'));
            if (localStorage.getItem('consent') !== 'true' || !settings) {
                return;
            }

            // 1. load configurator
            const configurator = settings.configurator;
            if (this.config.configurators.find(i => i.name === configurator)) {
                this.$store.commit('setConfiguator', configurator);
            }
            // 2. load subtitle
            this.$store.commit('setSubtitle', settings.subtitle);

            // 3. todo: restore data
            this.data.forEach(stepData => {
                const savedStepData = settings.data.find(s => s.id === stepData.id);
                if (!savedStepData) {
                    return;
                }
                if ('productGroups' in stepData && 'productGroups' in savedStepData) {
                    stepData.productGroups.forEach(productGroupData => {
                        const savedProductGroupData = savedStepData.productGroups.find(pg => pg.id === productGroupData.id);
                        if (!savedProductGroupData) {
                            return;
                        }
                        if (savedProductGroupData.skipped) {
                            productGroupData.skipped = true;
                        }
                        this.loadStateProducts(productGroupData.products, savedProductGroupData.products);

                        const productGroupObj = this.$store.getters.getProductGroup(productGroupData.id);
                        if (!productGroupObj.multiple && productGroupData.products.filter(p => p.selected).length > 1) {
                            // current config does not allow to choose more than one product for this product group
                            // BUT saved config has selected one than one product => deselect all and let user redo this
                            // step
                            productGroupData.products.forEach(p => { p.selected = false });
                        }
                    });
                }
                if ('fieldsets' in stepData && 'fieldsets' in savedStepData) {
                    stepData.fieldsets.forEach(fieldsetData => {
                        const savedFieldsetData = savedStepData.fieldsets.find(fs => fs.id === fieldsetData.id);
                        this.loadStateFieldset(fieldsetData, savedFieldsetData);
                    });
                }
            });
        },
        loadStateProducts(products, saveStateProducts) {
            saveStateProducts.forEach(saveStateProduct => {
                const product = products.find(p => p.id === saveStateProduct.id);
                if (!product) {
                    return;
                }
                if (saveStateProduct.selected) {
                    product.selected = true;
                }
                if ('options' in product && 'options' in saveStateProduct) {
                    this.loadStateProductOptions(saveStateProduct.id, product.options, saveStateProduct.options);
                }
            })
        },
        loadStateProductOptions(productId, options, saveStateOptions) {
            options.forEach(optionData => {
                const saveStateOptionData = saveStateOptions.find(o => o.id === optionData.id);
                if (!saveStateOptionData || saveStateOptionData.value.length === 0) {
                    return;
                }
                const option = this.$store.getters.getProductOption(productId, optionData.id);
                if (option.type === 'textfield') {
                    optionData.value = [saveStateOptionData.value[0]];
                } else {
                    saveStateOptionData.value.forEach(value => {
                        const valueObj = option.values.find(o => o.id === value);
                        if (valueObj) {
                            // only set data if the value actually exists in the current config
                            optionData.value.push(valueObj.id);
                        }
                    });
                    if (option.type !== 'checkbox' && optionData.value.length > 1) {
                        // only checkbox allows a multi selection
                        optionData.value = [optionData.value[0]];
                    }
                }
            })
        },
        loadStateFieldset(fieldsetData, savedFieldsetData) {
            fieldsetData.fields.forEach(fieldData => {
                const savedField = savedFieldsetData.fields.find(f => f.id === fieldData.id);
                if (Array.isArray(savedField.value) && savedField.value.length > 0) {
                    fieldData.value = savedField.value; // maybe do more sophisticated check here...
                }
            });
        },
        nextStep() {
            if (this.currentIndex === (this.steps.length - 1)) {
                this.sendForm();
            } else {
                this.currentIndex += 1;
                window.scrollTo(0,0);
            }
        },
        previousStep() {
            this.currentIndex -= 1;
            window.scrollTo(0,0);
        },
        showError(error, msg = '') {
            this.errorModal = true;
            this.errorMessage = msg;
            this.error = error;
        },
        sendForm() {
            this.submitBlock = true;
            this.submitBusy = true;
            this.submitError = '';
            this.submitErrorCode = '';
            this.submitModal = true;
            this.submitSuccess = false;
            this.captchaError = false;

            const request = {
                'captchaToken': this.captchaToken,
                'captchaSolution': this.captchaSolution,
                'data': this.dataFiltered
            };
            this.$axios.post(window.apiUrl + 'submit', request).then(() => {
                this.submitSuccess = true;
                localStorage.removeItem('settings');
            }).catch(error => {
                if (error.response) {
                    if ('message' in error.response.data && error.response.data.message.toLowerCase() === 'invalid captcha') {
                        this.fetchCaptcha();
                        this.captchaError = true;
                        this.submitModal = false;
                    } else if ('message' in error.response.data && error.response.data.message.toLowerCase() === 'invalid email') {
                        this.submitError = this.$tc('submit.errorEmail');
                    } else {
                        this.submitError = this.$tc('submit.error');
                    }
                } else {
                    this.submitError = this.$tc('submit.error');
                }

                this.submitBlock = false;
                this.fetchCaptcha();
                console.error(error);
            }).finally(() => {
                this.submitBusy = false;
            });
        },
        async fetchCaptcha() {
            if (this.captchaType !== 'internal') {
                return;
            }
            try {
                const response = await this.$axios.get(window.apiUrl + 'captcha');
                this.captchaImage = response.data.image;
                this.captchaToken = response.data.token;
                this.captchaSolution = '';
            } catch (e) {
                console.error(e);
            }
            clearTimeout(this.captchaTimeout);
            this.captchaTimeout = setTimeout(() => {this.fetchCaptcha();}, 570000);
        }
    }
}
</script>

<style lang="scss">

</style>
